'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

const IS_ENABLED =
  (process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'staging') &&
  !!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export function SegmentPageViewTracker() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!IS_ENABLED || !pathname) return;

    window?.analytics?.page({
      page: pathname,
    });
  }, [pathname, searchParams]);

  return null;
}

export function trackEvent(eventName, eventProps) {
  if (!IS_ENABLED) return null;

  window?.analytics?.track(eventName, eventProps);
}

export function identify(identifyProps) {
  if (!IS_ENABLED) return null;

  window?.analytics?.identify(identifyProps);
}
